.home-page-navbar-container {
  display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid #6c6c6c;
  background: linear-gradient(90deg, #191919 0%, #181818 100%);
  color: #fff;
}

.home-page-navbar-logo {
  display: flex;
}

.home-page-navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
}

.home-page-org-dropdown {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 0.2px solid var(--Border, #cdcdcd);
  background: var(--Background, #111);
  cursor: pointer;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

.nav-link:hover {
  background: linear-gradient(
    95deg,
    rgba(215, 236, 255, 0.4) 8.38%,
    rgba(177, 177, 177, 0.4) 91.62%
  );
}

.nav-link.active {
  border-radius: 8px;
  border: 0.2px solid #cdcdcd;
  background: linear-gradient(
    95deg,
    rgba(215, 236, 255, 0.4) 8.38%,
    rgba(177, 177, 177, 0.4) 91.62%
  );
}

/* Optional: Add a subtle glow effect */
.nav-link.active::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  border-radius: 8px;
  border: 0.2px solid #cdcdcd;
  background: linear-gradient(
    95deg,
    rgba(215, 236, 255, 0.4) 8.38%,
    rgba(177, 177, 177, 0.4) 91.62%
  );
}
