.my-dashboard-title {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.my-dashboard-subtitle {
  color: #cdcdcd;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.search-icon-css {
  color: #cdcdcd;
  margin-right: 8px;
}

.filter-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.filter-icon-container:hover {
  background: #2c2c2c;
}

.filter-icon-container img {
  width: 20px;
  height: 20px;
}

.my-agents-title {
  color: var(--Border, #cdcdcd);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 24px;
}

.single-agent-container {
  width: 280px;
  padding: 16px;
  background: #1c1c1c;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.single-agent-container:hover {
  border-color: #2b6be6;
}

.agent-name {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.created-by-title {
  color: #cdcdcd;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.agent-created-by-name {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.agent-description-title {
  color: #cdcdcd;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.agent-description {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
