/* Creator.css */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.my-dashboard-title {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.my-dashboard-subtitle {
  color: #cdcdcd;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.search-icon-css {
  color: #cdcdcd;
  margin-right: 8px;
}

.filter-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.filter-icon-container:hover {
  background: #2c2c2c;
}

.filter-icon-container img {
  width: 20px;
  height: 20px;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #1c1c1c;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #444444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b6be6;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #444444 #1c1c1c;
}

/* Class-specific scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1c1c1c;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #444444;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2b6be6;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #444444 #1c1c1c;
}
