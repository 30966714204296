.agent-details-modal-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--Outline, #2b2b2b);
  background: var(--Filler, #1c1c1c);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.agent-details-modal-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}

.created-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 0.2px solid var(--Border, #cdcdcd);
  background: var(--Outline, #2b2b2b);
}

.created-description-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.agent-created-by-name {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
}

.agent-description-text {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.cost-base-model-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 0.2px solid var(--Border, #cdcdcd);
  background: var(--Outline, #2b2b2b);
}

.cost-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-right: 1px solid var(--Subtext, #595959);
}

.model-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}
