.prose {
  line-height: 1.6;
  font-size: 1rem;
}

.prose p {
  margin-bottom: 1em;
}

.prose pre {
  margin: 1em 0;
  padding: 0;
  background: transparent;
}

.prose code {
  background-color: #000;
  padding: 0.2em 0;
  border-radius: 3px;
  font-size: 0.875em;
  font-family:
    ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.prose pre > div {
  margin: 0 !important;
  border-radius: 6px !important;
  background-color: #000 !important;
  border: 1px solid #2c2c2c !important;
  font-size: 0.9em !important;
  min-height: 2.5rem;
}

.prose button {
  transition: all 0.2s ease-in-out;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Animation for the copy button */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.prose pre button {
  animation: fadeIn 0.2s ease-in-out;
}

/* Table styles */
table {
  border-spacing: 0;
  border: 1px solid #333;
}

th,
td {
  margin: 0;
  border: 1px solid #333;
}

th {
  background-color: #252526;
}

tr:nth-child(even) {
  background-color: #1d1e20;
}

tr:nth-child(odd) {
  background-color: #1d1e20;
}

tr:hover {
  background-color: #2c2c2c;
}
