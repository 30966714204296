.left-back-icon {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  border: 0.5px solid var(--Subtext, #595959);
  background: var(--Outline, #2b2b2b);
  cursor: pointer;
}

.agent-name-title {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}

.agent-name-subtitle {
  color: rgba(205, 205, 205, 0.8);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.help-text {
  color: #eaeaea;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
}
.help-description {
  color: rgba(255, 255, 255, 0.5);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.pragya-ai-mistake-text {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
