.home-page-container {
  background-color: #070707;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.home-page-content {
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 10vh);
  height: 100%;
  border-radius: 8px;
  background: #070707;
}
.my-dashboard-title {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}
.my-dashboard-subtitle {
  color: rgba(205, 205, 205, 0.8);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.filter-icon-container {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 0.2px solid var(--Border, #cdcdcd);
  background: var(--Outline, #2b2b2b);
  cursor: pointer;
}

.agents-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--Outline, #2b2b2b);
  background: var(--Filler, #1c1c1c);
}
.favourite-agent-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.see-more-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  cursor: pointer;
}
.single-agent-container {
  width: 10%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 0.5px solid var(--Border, #cdcdcd);
  background: var(--Background, #111);
  cursor: pointer;
}
.agent-name {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.created-by-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
}
.agent-created-by-name {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}
.agent-description-title {
  color: var(--Border, #cdcdcd);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}
.agent-description {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
