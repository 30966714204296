:root {
  --Components-Upload-Global-borderRadiusLG: 8px;
  --Components-Upload-Global-lineWidth: 1px;
  --Outline: #2b2b2b;
  --Background: #111;
  --sds-size-radius-200: 8px;
}

.upload-area {
  border-radius: var(--Components-Upload-Global-borderRadiusLG, 8px);
  border: var(--Components-Upload-Global-lineWidth, 1px) solid
    var(--Outline, #2b2b2b);
  background: var(--Background, #111);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.upload-area:hover {
  border-color: #cdcdcd;
}

.upload-icon {
  width: 40px;
  height: 40px;
}

.upload-text {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 8px;
}

.form-field {
  border-radius: var(--sds-size-radius-200);
  border: 1px solid var(--Outline, #2b2b2b);
  background: var(--Background, #111);
}

/* Dropdown menu styles */
.MuiSelect-root,
.MuiMenuItem-root {
  color: #fff !important;
}

.MuiMenuItem-root:hover {
  background-color: rgba(43, 107, 230, 0.08) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(43, 107, 230, 0.16) !important;
}
