.agent-chat-history-modal-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--Outline, #2b2b2b);
  background: var(--Filler, #1c1c1c);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-history-container {
  width: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  gap: 12px;
  flex-shrink: 0;
  color: var(--Text, #cdcdcd);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Outline, #2b2b2b);
  background: var(--Background, #111);
}

.edit-icon {
  height: 20px;
  width: 20px;
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  border: 0.5px solid var(--Border, #cdcdcd);
  background: linear-gradient(
    95deg,
    rgba(215, 236, 255, 0.4) 8.38%,
    rgba(177, 177, 177, 0.4) 91.62%
  );
}

.delete-icon {
  height: 20px;
  width: 20px;
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  border: 0.5px solid var(--Border, #cdcdcd);
  background: linear-gradient(
    95deg,
    rgba(215, 236, 255, 0.4) 8.38%,
    rgba(177, 177, 177, 0.4) 91.62%
  );
}

.start-new-chat-button {
  width: 100%;
  display: flex;
  padding: 5px 15px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: var(--Components-Button-Global-borderRadiusLG, 8px);
  border: 0.2px solid #cdcdcd;
  box-shadow: 0px 2px 0px 0px
    var(--Components-Button-Global-controlOutline, rgba(5, 145, 255, 0.1));
}
