.agent-details-modal-container {
  color: white;
}

.agent-details-modal-title {
  color: white !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.created-description-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.created-description-title {
  color: #cdcdcd !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.agent-created-by-name {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.agent-description-text {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.cost-base-model-container {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.cost-container {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  background: #1c1c1c;
  border: 1px solid #cdcdcd;
}

.model-container {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  background: #1c1c1c;
  border: 1px solid #cdcdcd;
}
